import React from "react"
import { Button, Checkbox, Form, Input } from "antd"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"

const SignInForm = ({ onFinish }) => {

  const layout = {
    layout: "vertical"
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      {...layout}
      name="sign-in"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Nome utente o email"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Codice Utente - SCRIVI IN MAIUSCOLO"
        name="customer_code"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}


const SignInPage = () => {
  return null;
  //
  // const data = useStaticQuery(graphql`
  //     {
  //         wordpressSiteMetadata {
  //             home
  //             name
  //             url
  //         }
  //     }
  // `)
  //
  // const signIn = async ({ values }) => {
  //   try {
  //     const url = `${data.wordpressSiteMetadata.url}/jwt-auth/v1/token`
  //     const res = await axios({
  //       method: "post",
  //       url: url,
  //       data: values
  //     })
  //
  //     console.log({ res })
  //   } catch (e) {
  //
  //   }
  // }
  //
  // return (
  //   <div>
  //     <SignInForm onFinish={signIn} />
  //   </div>
  // )
}

export default SignInPage
